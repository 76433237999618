const mixin = {
    methods: {
        openInternalLink(link) {
            if (link) window.open(link, '_self');
        },
        openExternalLink(link) {
            if (link) window.open(link, '_blank');
        },
        copyToClipboard(text) {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText)
                return navigator.clipboard.writeText(text);
            return false;
        },
    },
};

export default mixin;
