<template>
  <div class="upm-block" v-if="show">
    <div class="upm-wrapper" data-aos="slide-in-up">
      <div class="upm-container">
        <div class="upm-text">{{ message ? message.description : description }}</div>
      </div>
      <div class="upm-actions">
        <button class="btn btn-accept" @click="acceptCookies">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LgpdMessage",
  data() {
    return {
      show: false,
      description: 'Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento.',
    };
  },
  props: {
    message: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.checkCookieExists();
  },
  methods: {
    acceptCookies() {
      this.setCookie('lgpd-cookie', 'true', 7);
      this.show = false;
    },
    checkCookieExists() {
      const lgpdCookie = this.getCookie('lgpd-cookie');
      if (lgpdCookie === 'true') return;
      this.show = true;
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    setCookie(cName, cValue, exDays) {
      const d = new Date();
      d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    },
  },
}
</script>

<style scoped>
.upm-block {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
}
.upm-wrapper {
  background: rgba(0, 0, 0, .7);
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  height: auto;
  padding: 20px;
  color: var(--color-white);
}
.upm-container {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
.btn {
  background: transparent;
  border: 2px solid rgba(255, 255, 255, .6);
  padding: 10px 30px;
  border-radius: 10px;
  color: white;
  transition: all ease-in-out .3s;
  filter:
      drop-shadow(0 1px 0 rgba(255, 255, 255, .2))
      drop-shadow(0 1px 0 rgba(255, 255, 255, .2))
      drop-shadow(0 1px 0 rgba(255, 255, 255, .1))
      drop-shadow(0 1px 0 rgba(255, 255, 255, .1))
      drop-shadow(0 1px 0 rgba(255, 255, 255, .1));
  cursor: pointer;
}
.btn:hover {
  transition: all ease-in-out .3s;
  filter:
      drop-shadow(0 1px 0 rgba(255, 255, 255, .2))
      drop-shadow(0 2px 0 rgba(255, 255, 255, .2))
      drop-shadow(0 3px 0 rgba(255, 255, 255, .1))
      drop-shadow(0 4px 0 rgba(255, 255, 255, .1))
      drop-shadow(0 5px 0 rgba(255, 255, 255, .1));
}
</style>