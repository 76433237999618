<template>
  <GlobalBackground>
    2022&copy;2022 Uparte.com.br - Todos os direitos reservados.
  </GlobalBackground>
  <LogoPresentation :person="person" />
  <CardContacts :person="person" />
  <CardPerson @person="checkPerson" />
  <LgpdMessage />
</template>

<script>
import GlobalBackground from "@/components/GlobalBackground.vue";
import LogoPresentation from "@/components/LogoPresentation.vue";
import LgpdMessage from "@/components/LgpdMessage.vue";
import CardContacts from "@/components/CardContacts.vue";
import CardPerson from "@/components/CardPerson.vue";

export default {
  name: 'App',
  data() {
    return {
      person: false,
    };
  },
  components: {
    CardPerson,
    CardContacts,
    LgpdMessage,
    LogoPresentation,
    GlobalBackground
  },
  methods: {
    checkPerson(person) {
      if (person) this.person = true;
    },
  },
}
</script>

<style>
@import "@/assets/app.css";
</style>
