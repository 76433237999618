<template>
  <section class="globalBackground" :style="{backgroundColor: color}">
    <footer class="credits">
      <slot></slot>
    </footer>
  </section>
</template>

<script>
export default {
  name: "GlobalBackground",
  data() {
    return {
      color: '#000000',
    };
  },
  beforeMount() {
    this.initChangeColor();
  },
  beforeUnmount() {
    this.destroyChangeColor();
  },
  methods: {
    initChangeColor() {
      const {changeColorController} = this;
      if (changeColorController) this.destroyChangeColor();
      this.changeColorController = setInterval(() => {
        this.colorGenerate();
      }, 7000);
      this.colorGenerate();
    },
    destroyChangeColor() {
      const {changeColorController} = this;
      if (changeColorController) clearInterval(changeColorController);
    },
    colorGenerate() {
      let color = "#";
      for (let i = 0; i < 3; i++)
        color += ("0" + Math.floor(Math.random() * Math.pow(32, 2) / 2).toString(16)).slice(-2);
      this.color = color;
    },
  },
}
</script>

<style scoped>
.globalBackground {
  display: inline-flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  transition: background-color 6.5s ease-in-out;
  overflow: hidden;
}
@keyframes aniBackground {
  0% {
    background-position: top left;
  }
  16% {
    background-position: top right;
  }
  32% {
    background-position: center center;
  }
  48% {
    background-position: bottom left;
  }
  64% {
    background-position: bottom right;
  }
  82% {
    background-position: center center;
  }
  100% {
    background-position: top left;
  }
}
.globalBackground:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: radial-gradient(var(--color-primary), transparent);
  background-size: 200vw 200vw;
  background-position: top left;
  animation: aniBackground linear 60s infinite;
}
.credits {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  width: 100%;
  flex: 1;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  font-size: .7rem;
  color: rgba(255, 255, 255, .7);
  text-align: center;
}

@media screen and (orientation:portrait) {
  .globalBackground:before {
    background-size: 200vh 200vh;
  }
}
</style>