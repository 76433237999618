<template>
  <div class="logo-area" :class="{ person: person }">
    <div class="logo-mask-01"></div>
    <div class="logo-wrapper">
      <div class="logo-img" data-label="Comunicação">
        <img src="@/assets/images/uparte-logo.svg" id="logo" alt="Uparte Comunicação" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoPresentation",
  props: {
    person: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>
.logo-area {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.logo-wrapper {
  width: 240px;
  height: 240px;
  position: relative;
  overflow: hidden;
}
@keyframes aniLogo {
  0% {
    transform: translateY(240px);
  }
  20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.logo-img {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform: translateY(240px);
  animation: aniLogo ease-in-out 1.8s 1s forwards;
}
.logo-img:after {
  content: attr(data-label);
  position: absolute;
  transform: translateY(40px);
  color: white;
  width: 200px;
  height: auto;
  text-align: right;
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 1px;
  filter:
      drop-shadow(0 0 0 rgba(0, 0, 0, .2))
      drop-shadow(0 0 0 rgba(0, 0, 0, .2))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1));
  animation: aniShadow ease-in-out .8s 1.8s forwards;
}
@keyframes aniShadow {
  0% {
    filter:
        drop-shadow(0 0 0 rgba(0, 0, 0, .2))
        drop-shadow(0 0 0 rgba(0, 0, 0, .2))
        drop-shadow(0 0 0 rgba(0, 0, 0, .1))
        drop-shadow(0 0 0 rgba(0, 0, 0, .1))
        drop-shadow(0 0 0 rgba(0, 0, 0, .1));
  }
  100% {
    filter:
        drop-shadow(2px 2px 0 rgba(0, 0, 0, .2))
        drop-shadow(4px 4px 1px rgba(0, 0, 0, .2))
        drop-shadow(6px 6px 2px rgba(0, 0, 0, .1))
        drop-shadow(8px 8px 3px rgba(0, 0, 0, .1))
        drop-shadow(10px 10px 4px rgba(0, 0, 0, .1));
  }
}
#logo {
  width: 200px;
  position: relative;
  filter:
      drop-shadow(0 0 0 rgba(0, 0, 0, .2))
      drop-shadow(0 0 0 rgba(0, 0, 0, .2))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1))
      drop-shadow(0 0 0 rgba(0, 0, 0, .1));
  animation: aniShadow ease-in-out .8s 1.8s forwards;
}
@keyframes aniSquare {
  0% {
    width: 125vw;
    height: 125vh;
    left: -12.5vw;
    top: -12.5vh;
    opacity: 1;
  }
  12.5% {
    width: 125vw;
    height: 125vh;
    left: calc(50vw - 120px);
    top: -12.5vh;
    opacity: 1;
  }
  25% {
    width: 125vw;
    height: 240px;
    left: calc(50vw - 120px);
    top: calc(50vh - 170px);
    opacity: 1;
  }
  50% {
    width: 240px;
    height: 240px;
    left: calc(50vw - 120px);
    top: calc(50vh - 170px);
    opacity: 1;
  }
  60% {
    width: 240px;
    height: 240px;
    left: calc(50vw - 120px);
    top: calc(50vh - 170px);
    opacity: 1;
  }
  100% {
    width: 125vw;
    height: 125vh;
    left: -12.5vw;
    top: -12.5vh;
    opacity: 0;
  }
}
.logo-mask-01 {
  width: 240px;
  height: 240px;
  background-color: var(--color-primary);
  position: absolute;
  left: calc(50vw - 120px);
  top: calc(50vh - 170px);
  animation: aniSquare ease-in-out 2s forwards;
}

@media screen and (orientation:landscape) and (max-height:780px)  {
  .logo-area:not(.person) .logo-mask-01,
  .logo-area:not(.person) .logo-wrapper {
    transform: translateX(-25vw);
  }
  @keyframes aniLogo {
    0% {
      transform: translateY(240px);
    }
    20% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>