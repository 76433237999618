<template>
  <section class="contacts fn-color white" :class="{ person: person }">
    <div class="contact-group" v-if="contact.phone">
      <div class="contact-group-icon icon-telephone" @click.prevent="openPhone"></div>
      <div class="contact-group-info" @click.prevent="openPhone">{{ contact.phone.text }}</div>
      <div class="contact-group-copy" @click.prevent="copyPhone">
        <div class="icon-clipboard small" v-if="!contact.phone.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="contact.address">
      <div class="contact-group-icon icon-geo" @click.prevent="openAddress"></div>
      <div class="contact-group-info" @click.prevent="openAddress">
        {{ contact.address.title }}
        <div class="contact-group-detail">{{ contact.address.text }}</div>
      </div>
      <div class="contact-group-copy" @click.prevent="copyAddress">
        <div class="icon-clipboard small" v-if="!contact.address.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="contact.whatsapp">
      <div class="contact-group-icon icon-whatsapp" @click.prevent="openWhatsApp"></div>
      <div class="contact-group-info" @click.prevent="openWhatsApp">{{ contact.whatsapp.text }}</div>
      <div class="contact-group-copy" @click.prevent="copyWhatsApp">
        <div class="icon-clipboard small" v-if="!contact.whatsapp.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="contact.email">
      <div class="contact-group-icon icon-envelope-at" @click.prevent="openMail"></div>
      <div class="contact-group-info" @click.prevent="openMail">
        {{ contact.email.value }}
      </div>
      <div class="contact-group-copy" @click.prevent="copyMail">
        <div class="icon-clipboard small" v-if="!contact.email.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="social-group mt-20" v-if="social">
      <div class="contact-group-social" @click.prevent="openInstagram" v-if="social.instagram">
        <div class="contact-group-icon icon-instagram large"></div>
      </div>
      <div class="contact-group-social" @click.prevent="openFacebook" v-if="social.facebook">
        <div class="contact-group-icon icon-facebook large"></div>
      </div>
    </div>
  </section>
</template>

<script>
import mixin from "@/mixins";

export default {
  name: "CardContacts",
  props: {
    person: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      social: {
        instagram: {
          url: "https://instagram.com/upartecomunicacao",
          text: "@upartecomunicacao",
        },
        facebook: {
          url: "https://fb.com/upartecomunicacao",
          text: "@upartecomunicacao",
        },
      },
      contact: {
        address: {
          title: 'Votuporanga/SP',
          value: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14956.076098990696!2d-49.9751889!3d-20.4232983!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xed139cea6f17161!2zVXBhcnRlIENvbXVuaWNhw6fDo28!5e0!3m2!1spt-BR!2sbr!4v1670967246815!5m2!1spt-BR!2sbr',
          text: 'R. São Paulo, 3320 - 10º Andar - Sala 103 - Patrimonio Novo, Votuporanga - SP, 15500-010',
          copied: false,
        },
        phone: {
          value: 996178184,
          text: '(17) 99617-8184',
          copied: false,
        },
        whatsapp: {
          value: 5517996178184,
          text: '(17) 99617-8184',
          copied: false,
        },
        email: {
          value: 'contato@uparte.com.br',
          copied: false,
        },
      },
      cleanCopyController: null,
    };
  },
  mixins: [mixin],
  methods: {
    resetCopy() {
      if (this.cleanCopyController) {
        clearTimeout(this.cleanCopyController);
        this.cleanCopy();
      }
      this.cleanCopyController = setTimeout(() => {
        this.cleanCopy();
      }, 3000);
    },
    cleanCopy() {
      this.contact.phone.copied = false;
      this.contact.address.copied = false;
      this.contact.whatsapp.copied = false;
      this.contact.email.copied = false;
    },
    openPhone() {
      const { phone } = this.contact;
      this.openExternalLink(`tel:${phone.value}`);
    },
    copyPhone() {
      const { phone } = this.contact;
      this.copyToClipboard(phone.value);
      this.resetCopy();
      phone.copied = true;
    },
    openAddress() {
      const { address } = this.contact;
      this.openExternalLink(address.value);
    },
    copyAddress() {
      const { address } = this.contact;
      this.copyToClipboard(address.text);
      this.resetCopy();
      address.copied = true;
    },
    openWhatsApp() {
      const { whatsapp } = this.contact;
      this.openExternalLink(`https://wa.me/${whatsapp.value}`);
    },
    copyWhatsApp() {
      const { whatsapp } = this.contact;
      this.copyToClipboard(whatsapp.value);
      this.resetCopy();
      whatsapp.copied = true;
    },
    openMail() {
      const { email } = this.contact;
      this.openExternalLink(`mailto:${email.value}`);
    },
    copyMail() {
      const { email } = this.contact;
      this.copyToClipboard(email.value);
      this.resetCopy();
      email.copied = true;
    },
    openInstagram() {
      const { instagram } = this.social;
      this.openExternalLink(instagram.url);
    },
    openFacebook() {
      const { facebook } = this.social;
      this.openExternalLink(facebook.url);
    },
  },
}
</script>

<style scoped>
.contacts {
  position: absolute;
  top: calc(50vh + 50px);
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
@keyframes appear {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.contact-group {
  width: 100%;
  max-width: 300px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  margin-bottom: 15px;
  transform: translateY(-15px);
  opacity: 0;
  animation: appear ease-in-out .5s 1.4s forwards;
}
.contact-group:nth-child(0) {
  animation-delay: 1.4s;
}
.contact-group:nth-child(1) {
  animation-delay: 1.6s;
}
.contact-group:nth-child(2) {
  animation-delay: 1.8s;
}
.contact-group:nth-child(3) {
  animation-delay: 2s;
}
.contact-group:nth-child(4) {
  animation-delay: 2.2s;
}
.contact-group:nth-child(5) {
  animation-delay: 2.4s;
}
.contact-group:nth-child(6) {
  animation-delay: 2.6s;
}
.social-group {
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  margin-bottom: 15px;
  opacity: 0;
  transform: translateY(-15px);
  animation: appear ease-in-out .5s 2.6s forwards;
}
.contact-group:before {
  content: "";
  display: block;
  left: -15px;
  top: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 20px;
  position: absolute;
  background-color: rgba(0, 0, 0, .2);
  transform: translateX(-20px);
  opacity: 0;
  transition: all ease-in-out .6s;
}
.contact-group:hover:before {
  transform: translateX(0);
  opacity: 100;
  transition: all ease-in-out .3s;
}
.contact-group-info {
  flex: 1;
  z-index: 1;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.contact-group-info.detail {
  width: 100%;
  font-size: .8rem;
  line-height: .8rem;
}
.contact-group-social,
.contact-group-copy {
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  cursor: copy;
  position: relative;
  margin-left: 6px;
}
.contact-group-social:before,
.contact-group-copy:before {
  content: "";
  display: block;
  left: -6px;
  top: -6px;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  position: absolute;
  background-color: rgba(0, 0, 0, .2);
  transform: translateX(20px);
  opacity: 0;
  transition: all ease-in-out .6s;
}
.contact-group-social:hover:before,
.contact-group-copy:hover:before {
  transform: translateX(0);
  opacity: 100;
  transition: all ease-in-out .3s;
}
.contact-group-social {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.contact-group-social:before {
  left: -2px;
  top: -2px;
  width: 52px;
  height: 52px;
}
.contact-group-icon {
  margin: 0 6px;
  cursor: pointer;
}
.contact-group-detail {
  font-size: .7rem;
  line-height: .8rem;
  white-space: normal;
}

@media screen and (orientation:landscape) and (max-height:780px)  {
  .contacts {
    top: 0;
    right: 0;
    height: 100%;
    width: calc(50vw - 120px);
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .contacts:not(.person) {
    width: 50vw;
    right: 20px;
  }
  .contact-group {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (orientation:portrait) {
  .contact-group {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}
</style>