<template>
  <section class="contacts fn-color white" v-if="selected">
    <div class="contact-group no-click" v-if="selected.name">
      <div class="contact-group-name mb-20">
        {{ selected.name }}
        <div class="contact-group-detail" v-if="selected.description">{{ selected.description }}</div>
      </div>
    </div>
    <div class="contact-group mt-20" v-if="selected.contact.phone">
      <div class="contact-group-icon icon-telephone" @click.prevent="openPhone"></div>
      <div class="contact-group-info" @click.prevent="openPhone">{{ selected.contact.phone.text }}</div>
      <div class="contact-group-copy" @click.prevent="copyPhone">
        <div class="icon-clipboard small" v-if="!selected.contact.phone.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="selected.contact.address">
      <div class="contact-group-icon icon-geo" @click.prevent="openAddress"></div>
      <div class="contact-group-info" @click.prevent="openAddress">
        {{ selected.contact.address.title }}
        <div class="contact-group-detail">{{ selected.contact.address.text }}</div>
      </div>
      <div class="contact-group-copy" @click.prevent="copyAddress">
        <div class="icon-clipboard small" v-if="!selected.contact.address.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="selected.contact.whatsapp">
      <div class="contact-group-icon icon-whatsapp" @click.prevent="openWhatsApp"></div>
      <div class="contact-group-info" @click.prevent="openWhatsApp">{{ selected.contact.whatsapp.text }}</div>
      <div class="contact-group-copy" @click.prevent="openWhatsAppPhone">
        <div class="contact-group-icon icon-telephone"></div>
      </div>
      <div class="contact-group-copy" @click.prevent="copyWhatsApp">
        <div class="icon-clipboard small" v-if="!selected.contact.whatsapp.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="contact-group" v-if="selected.contact.email">
      <div class="contact-group-icon icon-envelope-at" @click.prevent="openMail"></div>
      <div class="contact-group-info" @click.prevent="openMail">
        {{ selected.contact.email.value }}
      </div>
      <div class="contact-group-copy" @click.prevent="copyMail">
        <div class="icon-clipboard small" v-if="!selected.contact.email.copied"></div>
        <div class="icon-clipboard-check small" v-else></div>
      </div>
    </div>
    <div class="social-group" v-if="selected.social">
      <div class="contact-group-social" @click.prevent="openInstagram" v-if="selected.social.instagram">
        <div class="contact-group-icon icon-instagram large"></div>
      </div>
      <div class="contact-group-social" @click.prevent="openFacebook" v-if="selected.social.facebook">
        <div class="contact-group-icon icon-facebook large"></div>
      </div>
    </div>
  </section>
</template>

<script>
import mixin from "@/mixins";

export default {
  name: "CardPerson",
  data() {
    return {
      selected: null,
      contents: [
        {
          id: "ggiovanini",
          name: "Giuliano Giovanini",
          description: "Arte e Desenvolvimento",
          social: {
            instagram: {
              url: "https://instagram.com/ggiovanini",
              text: "@ggiovanini",
            },
            facebook: {
              url: "https://fb.com/ggiovanini",
              text: "@ggiovanini",
            },
          },
          contact: {
            whatsapp: {
              value: 5517981170051,
              call: 981170051,
              text: '(17) 98117-0051',
              copied: false,
            },
            email: {
              value: 'gil@uparte.com.br',
              copied: false,
            },
          },
          cleanCopyController: null,
        },
        {
          id: "bcamargo",
          name: "Bruno Camargo",
          description: "Comercial e Social",
          social: null,
          contact: {
            whatsapp: {
              value: 5517981622233,
              call: 981622233,
              text: '(17) 98162-2233',
              copied: false,
            },
            email: {
              value: 'brunocamargo@uparte.com.br',
              copied: false,
            },
          },
          cleanCopyController: null,
        },
        {
          id: "jaugusto",
          name: "Joaquim Augusto",
          description: "Compras e Financeiro",
          social: null,
          contact: {
            whatsapp: {
              value: 5517981315577,
              call: 981315577,
              text: '(17) 98131-5577',
              copied: false,
            },
            email: {
              value: 'joaquim@uparte.com.br',
              copied: false,
            },
          },
          cleanCopyController: null,
        },
      ],
    };
  },
  mixins: [mixin],
  beforeMount() {
    this.initContent();
  },
  methods: {
    initContent() {
      const urlPathData = window.location.pathname;
      const result = this.contents.find(item => item.id === urlPathData.replaceAll('/', ''));
      if (result) this.selected = result;
      this.$emit('person', result);
    },
    resetCopy() {
      if (this.cleanCopyController) {
        clearTimeout(this.cleanCopyController);
        this.cleanCopy();
      }
      this.cleanCopyController = setTimeout(() => {
        this.cleanCopy();
      }, 3000);
    },
    cleanCopy() {
      this.selected.contact.phone.copied = false;
      this.selected.contact.address.copied = false;
      this.selected.contact.whatsapp.copied = false;
      this.selected.contact.email.copied = false;
    },
    openPhone() {
      const { phone } = this.selected.contact;
      this.openExternalLink(`tel:${phone.value}`);
    },
    copyPhone() {
      const { phone } = this.selected.contact;
      this.copyToClipboard(phone.value);
      this.resetCopy();
      phone.copied = true;
    },
    openAddress() {
      const { address } = this.selected.contact;
      this.openExternalLink(address.value);
    },
    copyAddress() {
      const { address } = this.selected.contact;
      this.copyToClipboard(address.text);
      this.resetCopy();
      address.copied = true;
    },
    openWhatsApp() {
      const { whatsapp } = this.selected.contact;
      this.openExternalLink(`https://wa.me/${whatsapp.value}`);
    },
    openWhatsAppPhone() {
      const { whatsapp } = this.selected.contact;
      this.openExternalLink(`tel:${whatsapp.value}`);
    },
    copyWhatsApp() {
      const { whatsapp } = this.selected.contact;
      this.copyToClipboard(whatsapp.value);
      this.resetCopy();
      whatsapp.copied = true;
    },
    openMail() {
      const { email } = this.selected.contact;
      this.openExternalLink(`mailto:${email.value}`);
    },
    copyMail() {
      const { email } = this.selected.contact;
      this.copyToClipboard(email.value);
      this.resetCopy();
      email.copied = true;
    },
    openInstagram() {
      const { instagram } = this.social;
      this.openExternalLink(instagram.url);
    },
    openFacebook() {
      const { facebook } = this.social;
      this.openExternalLink(facebook.url);
    },
  },
}
</script>

<style scoped>
.contacts {
  position: absolute;
  top: 50px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
@keyframes appear {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.contact-group {
  width: 100%;
  max-width: 300px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  margin-bottom: 15px;
  transform: translateY(-15px);
  opacity: 0;
  animation: appear ease-in-out .5s 2s forwards;
}
.contact-group.no-click {
  pointer-events: none;
}
.contact-group:nth-child(0) {
  animation-delay: 2s;
}
.contact-group:nth-child(1) {
  animation-delay: 2.2s;
}
.contact-group:nth-child(2) {
  animation-delay: 2.4s;
}
.contact-group:nth-child(3) {
  animation-delay: 2.6s;
}
.contact-group:nth-child(4) {
  animation-delay: 2.8s;
}
.contact-group:nth-child(5) {
  animation-delay: 3s;
}
.contact-group:nth-child(6) {
  animation-delay: 3.2s;
}
.social-group {
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  margin-bottom: 15px;
  opacity: 0;
  transform: translateY(-15px);
  animation: appear ease-in-out .5s 3.2s forwards;
}
.contact-group:before {
  content: "";
  display: block;
  left: -15px;
  top: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 20px;
  position: absolute;
  background-color: rgba(0, 0, 0, .2);
  transform: translateX(-20px);
  opacity: 0;
  transition: all ease-in-out .6s;
}
.contact-group:hover:before {
  transform: translateX(0);
  opacity: 100;
  transition: all ease-in-out .3s;
}
.contact-group-name,
.contact-group-info {
  flex: 1;
  z-index: 1;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}
.contact-group-name {
  align-items: center;
  font-size: 1.2rem;
}
.contact-group-social,
.contact-group-copy {
  width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  cursor: copy;
  position: relative;
  margin-left: 6px;
}
.contact-group-social:before,
.contact-group-copy:before {
  content: "";
  display: block;
  left: -6px;
  top: -6px;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  position: absolute;
  background-color: rgba(0, 0, 0, .2);
  transform: translateX(20px);
  opacity: 0;
  transition: all ease-in-out .6s;
}
.contact-group-social:hover:before,
.contact-group-copy:hover:before {
  transform: translateX(0);
  opacity: 100;
  transition: all ease-in-out .3s;
}
.contact-group-social {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.contact-group-social:before {
  left: -2px;
  top: -2px;
  width: 52px;
  height: 52px;
}
.contact-group-icon {
  margin: 0 6px;
  cursor: pointer;
}
.contact-group-detail {
  font-size: .7rem;
  line-height: .8rem;
}

@media screen and (orientation:landscape) and (max-height:780px)  {
  .contacts {
    top: 0;
    height: 100%;
    width: calc(50vw - 120px);
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .contact-group {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (orientation:portrait) {
  .contact-group {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}
</style>